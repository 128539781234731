@import "/home/dmcb/dmcb_website/assets/css/variables/colors.scss";


body {
	background-color: $white;
	color: $black;
	font-family: 'Helvetica', sans-serif;
	font-weight: 200;
	padding: 0;
	margin: 0;

	&.blackbg {
		background-color: #000;
	}
}

.max-full { max-width:100%; }

.center_block {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.bottom_block {
	text-align: center;
	position: absolute;
	bottom: 10px;
	left: 50%;
	transform: translateX(-50%);
}

.screen {
	height: 100vh;
	position: relative;

	&.half {
		height: 50vh;
	}

	&.color1 {
		background-color: $color1;
	}

	&.color2 {
		background-color: $color2;
	}
}

.main-video { margin:32px auto; text-align:center; }

.about-us { width:800px; max-width:95%; margin:16px auto; }

// animations
.vertical_pulsate {
	animation: vertical_pulsate 3s infinite;
}

@keyframes vertical_pulsate {
	0% { transform: translate(-50%, 0px); }
	80% { transform: translate(-50%, 0px); }
	85% { transform: translate(-50%, 10px); }
	90% { transform: translate(-50%, 0px); }
	95% { transform: translate(-50%, 10px); }
	100% { transform: translate(-50%, 0px); }
}

// typography
p {
	font-size: 20px;
	margin-bottom: 10px;
	margin-top: 0px;
}

a {
	color: $white;
}

.huge {
	font-size: 75px;
	font-weight: 100;
}

// lists
ul {
	text-align: left;
}

li {
	list-style-type: none;
}
